import { GetStaticPaths, GetStaticProps } from 'next';
import { getComments, getRouteData } from 'utls';
import dynamic from 'next/dynamic';
const PostTypeRenderer = dynamic(
  () => import('../components/postTypes/PostTypeRenderer')
);
export default function Page() {
  return <PostTypeRenderer />;
}
export const getStaticProps: GetStaticProps = async context => {
  // const { slug } = context?.params || {};
  // const { locale } = context || {};
  // console.log(locale, 'localelocale');

  // const path = `/${slug?.join('/') ?? ''}`;
  // const data = await getRouteData(path, locale);

  // return {
  //   props: data,
  //   revalidate: 60 * 20
  // };

  try {
    const { slug } = context?.params || {};
    const { locale } = context || {};

    const path = `/${slug?.join('/') ?? ''}`;
    const data = await getRouteData(path, locale);

    return {
      props: data,
      revalidate:
        data?.route_data?.type === 'page' ? 60 * 30 * 1000 : 60 * 60 * 1000
    };
  } catch (e) {
    return {
      notFound: true
    };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: 'blocking' };
};
